import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useRoutings() {
  const t = inject("t");
  const store = useStore();
  const applications = ref([]);
  const inbound = ref({});
  const inbounds = ref([]);
  const flows = ref([]);
  const outbound = ref({});
  const outbounds = ref([]);
  const timeslots = ref([]);
  const loading = ref(true);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND INBOUND ROUTINGS
  const findInbounds = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/routing/inbound");
      if (response?.data) inbounds.value = response.data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND OUTNBOUND ROUTINGS
  const findOutbounds = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/routing/outbound");
      if (response?.data) inbounds.value = response.data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND IVRS
  const findIVRS = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/flow");
      if (response?.data) {
        const flowsArray = response.data.map((i) => {
          return {
            value: i.id,
            name: i.name,
          };
        });

        console.log(flowsArray);

        flows.value = flowsArray;
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  // Find timeslots
  const findTimeslots = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/timezone");
      if (response?.data) {
        timeslots.value = response.data.map((i) => {
          return {
            value: i.id,
            name: i.description,
          };
        });

        console.log(timeslots.value);
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  // FIND APPLICATIONS
  const findApplications = async () => {
    try {
      const response = await api.get("/admin/applications");
      if (response?.data) {
        applications.value = response.data.map((i) => {
          return {
            value: i.id,
            name: i.name,
          };
        });

        console.log(applications.value);
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   CREATE INBOUND ROUTING
  const createInbound = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/routing/inbound`,
        data
      );

      if (response?.data) {
        inbounds.value = [response.data, ...inbounds.value];

        n(
          "xpbx.settings.routings.notifications.create_routing_inbound",
          "success"
        );
      } else {
        n("xpbx.settings.routings.notifications.error_create_routing_inbound");
      }
    } catch (error) {
      console.log(error);

      n("xpbx.settings.routings.notifications.error_create_routing_inbound");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE  INBOUND ROUTING
  const updateInbound = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/routing/inbound/${id}`,
        data
      );

      if (response?.data) {
        const index = inbounds.value.findIndex((i) => i.id === id);
        inbounds.value[index] = response.data;
        n(
          "xpbx.settings.routings.notifications.edit_routing_inbound",
          "success"
        );
      } else {
        n("xpbx.settings.routings.notifications.error_edit_routing_inbound");
      }
    } catch (error) {
      n("xpbx.settings.routings.notifications.error_edit_routing_inbound");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE INBOUND ROUTING
  const deleteInbound = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/routing/inbound/${id}`
      );

      if (response?.status === 200) {
        inbounds.value = inbounds.value.filter((i) => i.id !== id);
        n(
          "xpbx.settings.routings.notifications.delete_routing_inbound",
          "success"
        );
      } else {
        n("xpbx.settings.routings.notifications.error_delete_routing_inbound");
      }
    } catch (error) {
      n("xpbx.settings.routings.notifications.error_delete_routing_inbound");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    flows,
    loading,
    inbound,
    inbounds,
    outbound,
    outbounds,
    applications,
    timeslots,

    findIVRS,
    findInbounds,
    findOutbounds,
    createInbound,
    updateInbound,
    deleteInbound,
    findTimeslots,
    findApplications,
  };
}
