<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <!-- Extensions table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="inbounds"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          :paginator="true"
          v-if="!loading"
          :rows="10"
          :filters="filters"
          v-model:selection="selectedRecords"
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          :globalFilterFields="['carrier_name']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
                <Export
                  :dt="dt"
                  :tableData="mappedInbounds"
                  :columns="inboundHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="a_regexp"
            style="width: 16%"
            :header="$t('xpbx.settings.routings.fields.a_regexp')"
          >
            <template #body="{ data }">
              {{ data?.a_regexp }}
              <div>
                <TableActions
                  :data="data"
                  :id="data.id"
                  :showEdit="true"
                  :showIsActive="false"
                  @edit="editHandle"
                  @delete="deleteSelectedRecord"
                />
              </div>
            </template>
          </Column>
          <Column
            sortable
            style="width: 16%"
            field="b_regexp"
            :header="$t('xpbx.settings.routings.fields.b_regexp')"
          />
          <Column
            sortable
            style="width: 16%"
            field="flow_id"
            :header="$t('xpbx.settings.routings.fields.flow_id')"
          >
            <template #body="{ data }">
              {{ getFlow(data.flow_id) }}
            </template>
          </Column>
          <Column
            sortable
            style="width: 16%"
            field="priority"
            :header="$t('xpbx.settings.routings.fields.priority')"
          />
          <Column
            sortable
            style="width: 16%"
            field="timeslot_id"
            :header="$t('xpbx.settings.routings.fields.timeslot_id')"
          >
            <template #body="{ data }">
              {{ getTimeslot(data.timeslot_id) }}
            </template>
          </Column>
          <Column
            sortable
            style="width: 16%"
            field="application_id"
            :header="$t('xpbx.settings.routings.fields.application_id')"
          >
            <template #body="{ data }">
              {{ getApplication(data.application_id) }}
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span class="text-center">{{
            $t("xpbx.settings.routings.notifications.confirm_delete", {
              delete: `inbound ${
                selectedRecord?.a_regexp ? selectedRecord.a_regexp : ""
              }`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create Carrier -->
      <Dialog
        v-model:visible="carrierDialog"
        :style="{ width: '800px' }"
        :header="
          isEdit
            ? $t('xpbx.settings.routings.edit_inbound')
            : $t('xpbx.settings.routings.create_inbound')
        "
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- A regexp -->
        <FormInput
          id="a_regexp"
          :error="errors?.a_regexp?.[0]"
          v-model:modelValue="inbound.a_regexp"
          :label="$t('xpbx.settings.routings.labels.a_regexp')"
          :hint="$t('xpbx.settings.routings.hints.a_regexp')"
        />

        <!-- B regexp -->
        <FormInput
          id="a_regexp"
          :error="errors?.b_regexp?.[0]"
          v-model:modelValue="inbound.b_regexp"
          :label="$t('xpbx.settings.routings.labels.b_regexp')"
          :hint="$t('xpbx.settings.routings.hints.b_regexp')"
        />

        <!-- Timezones -->
        <FormDropdown
          :options="timeslots"
          optionLabel="name"
          :error="errors?.timeslot_id?.[0]"
          v-model:modelValue="inbound.timeslot_id"
          :label="$t('xpbx.settings.routings.labels.timeslot_id')"
          :hint="$t('xpbx.settings.routings.hints.timeslot_id')"
        />

        <!-- Flows -->
        <FormDropdown
          :options="flows"
          optionLabel="name"
          :error="errors?.flow_id?.[0]"
          v-model:modelValue="inbound.flow_id"
          :label="$t('xpbx.settings.routings.labels.flow_id')"
          :hint="$t('xpbx.settings.routings.hints.flow_id')"
        />

        <!-- Priority -->
        <FormDropdown
          :options="priorityOptions"
          :error="errors?.priority?.[0]"
          v-model:modelValue="inbound.priority"
          :label="$t('xpbx.settings.routings.labels.priority')"
          :hint="$t('xpbx.settings.routings.hints.priority')"
        />

        <!-- Application -->
        <FormDropdown
          optionLabel="name"
          :options="applications"
          :error="errors?.application_id?.[0]"
          v-model:modelValue="inbound.application_id"
          :label="$t('xpbx.settings.routings.labels.application_id')"
          :hint="$t('xpbx.settings.routings.hints.application_id')"
        />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="carrierDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, watch, computed, inject } from "vue";
import useRoutings from "@/modules/xpbx/composables/useRoutings";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Timezones from "@/shared/Timezones";
import Tooltip from "primevue/tooltip";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { validateCreateRouting } from "@/composables/auth/createNumberValidations";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import { inboundHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "Inbound",

  components: {
    DataTable,
    Column,
    Dropdown,
    Loader,
    Button,
    InputText,
    Dialog,
    Timezones,
    Export,
    FormInput,
    DialogButtons,
    FormDropdown,
    TableActions,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const store = useStore();
    // Datatable
    const dt = ref();
    const t = inject("t");
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const carrierDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const selectText = t("xpbx.settings.dropdown.select_option");
    const { priorityOptions, getOptionValue, fileTypeQueueOptions } =
      useQueueOptions();
    const {
      inbounds,
      inbound,
      flows,
      loading,
      findInbounds,
      timeslots,
      findTimeslots,
      findIVRS,
      applications,
      createInbound,
      updateInbound,
      deleteInbound,
      findApplications,
    } = useRoutings();

    const mappedInbounds = computed(() => {
      return inbounds.value.map((inbound) => {
        return {
          ...inbound,
          flow_id: getFlow(inbound.flow_id),
          timeslot_id: getTimeslot(inbound.timeslot_id),
          application_id: getApplication(inbound.application_id),
        };
      });
    });

    const setDefaultRecord = () => {
      inbound.value = {
        a_regexp: "",
        b_regexp: "",
        flow_id: {
          value: null,
          name: selectText,
        },
        timeslot_id: {
          value: null,
          name: selectText,
        },
        application_id: {
          value: null,
          name: selectText,
        },
        priority: selectText,
      };
    };

    const resetCarrier = () => {
      setDefaultRecord();
      submitted.value = false;
      clearErrors();
    };

    const openNew = () => {
      setDefaultRecord();
      carrierDialog.value = true;
    };

    const closeDialog = (value) => {
      carrierDialog.value = value;
    };

    const createCallback = () => {
      submitted.value = false;
      carrierDialog.value = false;
    };

    const createRecord = async (formData) => {
      try {
        await createInbound(formData);
      } catch (error) {
      } finally {
        createCallback();
      }
    };

    const updateRecord = async (formData) => {
      try {
        await updateInbound(formData, selectedRecord.value.id);
      } catch (error) {
      } finally {
        createCallback();
      }
    };

    const create = async () => {
      const formData = {
        ...inbound.value,
        flow_id: getOptionValue(inbound.value.flow_id),
        priority: inbound.value.priority,
        timeslot_id: getOptionValue(inbound.value.timeslot_id),
        application_id: getOptionValue(inbound.value.application_id),
      };

      const isValid = validateCreateRouting(formData);

      if (!isValid) return;

      if (isEdit.value) {
        await updateRecord(formData);
      } else {
        await createRecord(formData);
      }
    };

    const editHandle = (data) => {
      const exFLow = flows.value.find((flow) => flow.value === data.flow_id);
      const exTImeslot = timeslots.value.find(
        (timeslot) => timeslot.value === data.timeslot_id
      );
      const exApplication = applications.value.find(
        (service) => service.value === data.application_id
      );

      const form = {
        a_regexp: data.a_regexp,
        b_regexp: data.b_regexp,
        flow_id: {
          value: exFLow?.value || null,
          name: exFLow?.name || selectText,
        },
        timeslot_id: {
          value: exTImeslot?.value || null,
          name: exTImeslot?.name || selectText,
        },
        application_id: {
          value: exApplication?.value || null,
          name: exApplication?.name || selectText,
        },
        priority: data.priority,
      };

      inbound.value = form;
      isEdit.value = true;
      selectedRecord.value = data;

      carrierDialog.value = true;
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = async (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteInbound(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const getFlow = (id) => {
      const flow = flows.value.find((flow) => flow.value === id);
      return flow?.name || "";
    };

    const getTimeslot = (id) => {
      const timeslot = timeslots.value.find(
        (timeslot) => timeslot.value === id
      );
      return timeslot?.name || "";
    };

    const getApplication = (id) => {
      const application = applications.value.find(
        (service) => service.value === id
      );
      return application?.name || "";
    };

    const servicesOptions = [
      {
        name: t(`xpbx.settings.carrier_trunks.options.voip`),
        value: "0f68b7cf-729c-b72f-bc5b-31b4bb0c3e43",
      },
      {
        name: t(`xpbx.settings.carrier_trunks.options.moip`),
        value: "e8a72c4f-3549-597d-46dd-652970533864",
      },
    ];

    watch(carrierDialog, (value) => {
      if (!value) resetCarrier();
    });

    onMounted(async () => {
      await findIVRS();
      await findInbounds();
      await findTimeslots();
      await findApplications();
    });

    return {
      dt,
      flows,
      isEdit,
      inbound,
      inbounds,
      loading,
      timeslots,
      errors,
      filters,
      submitted,
      applications,
      inboundHeaders,
      mappedInbounds,
      priorityOptions,
      fileTypeQueueOptions,
      carrierDialog,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      scrollHeight,
      // Methods
      create,
      getFlow,
      openNew,
      editHandle,
      getTimeslot,
      closeDialog,
      findInbounds,
      getApplication,
      setDefaultRecord,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
      resetCarrier,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>