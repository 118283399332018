<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <!-- Extensions table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="carriers"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          :paginator="true"
          v-if="!loading"
          :rows="10"
          :filters="filters"
          v-model:selection="selectedRecords"
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} carriers"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          :globalFilterFields="['carrier_name']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
                <Export
                  :dt="dt"
                  :tableData="carriers"
                  :columns="carriersHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="carrier_name"
            style="width: 50%"
            :header="$t('xpbx.settings.routings.fields.carrier_name')"
          >
            <template #body="{ data }">
              {{ data?.carrier_name }}
              <div>
                <TableActions
                  :data="data"
                  :id="data.id"
                  :showEdit="false"
                  :showIsActive="false"
                  :link="`/xpbx/settings/carrier/${data?.id}`"
                  @setActive="setActive"
                  @delete="deleteSelectedRecord"
                />
              </div>
            </template>
          </Column>
          <Column
            sortable
            style="width: 50%"
            field="timezone"
            :header="$t('xpbx.settings.routings.fields.timezone')"
          />
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span class="text-center">{{
            $t("xpbx.settings.routings.notifications.confirm_delete", {
              delete: `carrier ${
                selectedRecord?.carrier_name ? selectedRecord.carrier_name : ""
              }`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create Carrier -->
      <Dialog
        v-model:visible="carrierDialog"
        :style="{ width: '800px' }"
        :header="$t('xpbx.settings.routings.dialogs.create_carrier')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Carrier name -->
        <FormInput
          id="carrier_name"
          :error="errors?.carrier_name?.[0]"
          v-model:modelValue="carrier.carrier_name"
          :label="$t('xpbx.settings.routings.labels.carrier_name')"
          :hint="$t('xpbx.settings.routings.hints.carrier_name')"
        />

        <!-- Ussername -->
        <FormInput
          id="username"
          :error="errors?.username?.[0]"
          v-model:modelValue="carrier.username"
          :label="$t('xpbx.settings.routings.labels.username')"
          :hint="$t('xpbx.settings.routings.hints.username')"
        />

        <!-- Password -->
        <FormInput
          id="password"
          type="password"
          :error="errors?.password?.[0]"
          v-model:modelValue="carrier.password"
          :label="$t('xpbx.settings.routings.labels.password')"
          :hint="$t('xpbx.settings.routings.hints.password')"
        />

        <!-- Timezones -->
        <Timezones
          v-model="carrier.timezone"
          :showTitle="false"
          label="label.app.common.timezone_select"
        />

        <!-- currency -->
        <FormDropdown
          :options="curencies"
          v-model:modelValue="carrier.currency"
          :label="$t('xpbx.settings.routings.labels.currency')"
          :hint="$t('xpbx.settings.routings.hints.currency')"
        />

        <!-- is did supplier -->
        <InputSwitch
          v-model:modelValue="carrier.is_did_supplier"
          :label="$t('xpbx.settings.routings.labels.is_did_supplier')"
          :hint="$t('xpbx.settings.routings.hints.is_did_supplier')"
        />

        <!-- is Vioce supplier -->
        <InputSwitch
          v-model:modelValue="carrier.is_voice_supplier"
          :label="$t('xpbx.settings.routings.labels.is_voice_supplier')"
          :hint="$t('xpbx.settings.routings.hints.is_voice_supplier')"
        />

        <!-- is SMS supplier -->
        <InputSwitch
          v-model:modelValue="carrier.is_sms_supplier"
          :label="$t('xpbx.settings.routings.labels.is_sms_supplier')"
          :hint="$t('xpbx.settings.routings.hints.is_sms_supplier')"
        />

        <!-- Is active -->
        <InputSwitch
          v-model:modelValue="carrier.is_active"
          :label="$t('xpbx.settings.routings.labels.is_active')"
        />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="carrierDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, watch, computed } from "vue";
import useRoutings from "@/modules/xpbx/composables/useRoutings";
import useCarriers from "@/modules/xpbx/composables/useCarriers";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Timezones from "@/shared/Timezones";
import Tooltip from "primevue/tooltip";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { validateCreateCarrier } from "@/composables/auth/createNumberValidations";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import { carriersHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "Outbound",

  components: {
    DataTable,
    Column,
    Dropdown,
    Loader,
    Button,
    InputText,
    Dialog,
    Timezones,
    Export,
    FormInput,
    DialogButtons,
    InputSwitch,
    FormDropdown,
    TableActions,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const {
      findCarriers,
      loading,
      carriers,
      carrier,
      createCarrier,
      updateCarrier,
      deleteCarrier,
    } = useCarriers();
    const store = useStore();
    // Datatable
    const dt = ref();
    const submitted = ref(false);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const carrierDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const { outbounds, outbound, findOutbounds } = useRoutings();

    const resetCarrier = () => {
      carrier.value = {
        carrier_name: "",
        timezone: "Europe/Sofia",
        is_active: true,
        currency: "EUR",
        username: "",
        password: "",
        is_did_supplier: false,
        is_sms_supplier: false,
        is_voice_supplier: false,
      };
      submitted.value = false;
      clearErrors();
    };

    const timezones = computed(() => store.getters.timezoneOptions);

    const openNew = () => {
      carrierDialog.value = true;
    };

    const closeDialog = (value) => {
      carrierDialog.value = value;
    };

    const createCallback = () => {
      submitted.value = false;
      carrierDialog.value = false;
    };

    const createRecord = async () => {
      submitted.value = true;
      const isValid = validateCreateCarrier(carrier.value);

      if (isValid) {
        const formData = {
          carrier_name: carrier.value.carrier_name,
          currency: carrier.value.currency,
          is_active: carrier.value.is_active === true ? 1 : 0,
          is_did_supplier: carrier.value.is_did_supplier === true ? 1 : 0,
          is_sms_supplier: carrier.value.is_sms_supplier === true ? 1 : 0,
          is_voice_supplier: carrier.value.is_voice_supplier === true ? 1 : 0,
        };

        if (carrier.value.username) formData.username = carrier.value.username;
        if (carrier.value.password) formData.password = carrier.value.password;

        if (carrier.value.timezone)
          formData.timezone = carrier.value.timezone?.value
            ? carrier.value.timezone?.value
            : carrier.value.timezone || "";

        try {
          await createCarrier(formData);
          createCallback();
        } catch (error) {
          createCallback();
        }
      }
    };

    const create = async () => {
      await createRecord();
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = async (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteCarrier(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const setActive = async (data) => {
      const form = {
        timezone: data.timezone,
        carrier_name: data.carrier_name,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateCarrier(form, data.id);
    };

    const curencies = ["USD", "EUR"];

    watch(carrierDialog, (value) => {
      if (!value) resetCarrier();
    });

    onMounted(async () => {
      await findCarriers();
      await findOutbounds();
    });

    return {
      dt,
      curencies,
      loading,
      outbound,
      outbounds,
      carriersHeaders,
      errors,
      filters,
      submitted,
      carrier,
      carriers,
      timezones,
      carrierDialog,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      scrollHeight,
      // Methods
      create,
      openNew,
      setActive,
      closeDialog,
      findOutbounds,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
      resetCarrier,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>