<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.settings.routing.heading')"
        :showButton="false"
        :buttonTitle="$t('xpbx.button.create_user')"
      />
      <TabView :activeIndex="activeTabIndex" @tab-change="onTabChange">
        <TabPanel
          v-for="(tab, index) in tabs"
          :key="index"
          :header="tab?.label"
        >
          <div>
            <Inbound v-if="tab.key === 'inbound'" />
            <Outbound v-if="tab.key === 'outbound'" />
          </div>
        </TabPanel>
      </TabView>
    </section>
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Outbound from "@/modules/xpbx/pages/settings/routing/components/Outbound.vue";
import Inbound from "@/modules/xpbx/pages/settings/routing/components/Inbound.vue";

export default {
  name: "Routing",

  components: {
    PageHeader,
    TabView,
    TabPanel,
    Outbound,
    Inbound,
  },

  setup() {
    const t = inject("t");
    const activeTabIndex = ref(0);

    const tabs = computed(() => {
      return [
        {
          key: "inbound",
          label: t("xpbx.settings.routing.tabs.inbound"),
        },
        {
          key: "outbound",
          label: t("xpbx.settings.routing.tabs.outbound"),
        },
      ];
    });

    const onTabChange = (e) => {
      activeTabIndex.value = e.index;
    };

    return {
      tabs,
      activeTabIndex,
      // Methods
      onTabChange,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>